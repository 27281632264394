// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Countdown from './pages/Countdown/index.jsx';
import { DateTime } from 'luxon';
import './styles/index.css';
import RegionSelection from './pages/RegionSelection/index.jsx';

const App = () => {
  const manchesterDate = DateTime.fromObject(
    { year: 2025, month: 4, day: 8, hour: 10, minute: 0, second: 0 },
    { zone: 'Europe/London' }
  ).toJSDate();

  const chicagoManchesterNADate = DateTime.fromObject(
    { year: 2025, month: 4, day: 8, hour: 10, minute: 0, second: 0 },
    { zone: 'America/Chicago' }
  ).toJSDate();

  const phoenixDate = DateTime.fromObject(
    { year: 2025, month: 4, day: 8, hour: 10, minute: 0, second: 0 },
    { zone: 'America/Phoenix' }
  ).toJSDate();

  const brisbaneManchesterAusDate = DateTime.fromObject(
    { year: 2025, month: 4, day: 8, hour: 10, minute: 0, second: 0 },
    { zone: 'Australia/Brisbane' }
  ).toJSDate();

  // Set the target date to 1 minute from now
  const targetDate = new Date(Date.now() + 600 * 10000);
  const targetDateOneMinuteFromNow = new Date(Date.now() + 60 * 1000);
  const targetDateOneMinuteBeforeNow = new Date(Date.now() - 60 * 1000);


  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={<RegionSelection />} />
            <Route path="/bst" element={<Countdown targetDate={manchesterDate} timezone={'10am BST'}/>} />
            <Route path="/cdt" element={<Countdown targetDate={chicagoManchesterNADate} timezone={'10am CDT'}/>} />
            <Route path="/mst" element={<Countdown targetDate={phoenixDate} timezone={'10am MST'}/>} />
            <Route path="/aest" element={<Countdown targetDate={brisbaneManchesterAusDate} timezone={'10am AEST'}/>} />
            <Route path="/test" element={<Countdown targetDate={targetDate} />} />
            <Route path="/test2" element={<Countdown targetDate={targetDateOneMinuteFromNow} />} />
            <Route path="/test3" element={<Countdown targetDate={targetDateOneMinuteBeforeNow} />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
