import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/Logo.svg';
import ball1 from '../../assets/Purple.svg'; 
import ball2 from '../../assets/Yellow.svg';
import ball3 from '../../assets/Green.svg';
import ball4 from '../../assets/Pink.svg';

const RegionSelection = () => {
  // Refs for each ball
  const ball1Ref = useRef(null);
  const ball2Ref = useRef(null);
  const ball3Ref = useRef(null);
  const ball4Ref = useRef(null);

  // Move balls away from mouse
  const handleMouseMove = (e) => {
    const balls = [ball1Ref.current, ball2Ref.current, ball3Ref.current, ball4Ref.current];
    const mouseX = e.clientX;
    const mouseY = e.clientY;
  
    balls.forEach((ball) => {
      if (ball) {
        const ballRect = ball.getBoundingClientRect();
        const ballCenterX = ballRect.left + ballRect.width / 2;
        const ballCenterY = ballRect.top + ballRect.height / 2;
  
        const distanceX = ballCenterX - mouseX;
        const distanceY = ballCenterY - mouseY;
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
  
        const maxRange = 300;  // Increase the range of interaction
        const maxMove = 50;    // Max distance a ball can move from its original position
  
        if (distance < maxRange) {
          // Move based on proximity, scaled by how close the mouse is (closer = more movement)
          const proximityFactor = (maxRange - distance) / maxRange;  // Scale from 0 (far) to 1 (close)
          const moveX = (distanceX / distance) * maxMove * proximityFactor;
          const moveY = (distanceY / distance) * maxMove * proximityFactor;
  
          // Apply the transform for smooth movement
          ball.style.transform = `translate(${moveX}px, ${moveY}px)`;
        } else {
          // Reset the transform when far away
          ball.style.transform = 'translate(0, 0)';
        }
      }
    });
  };
  
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="countdown-container">
      <div className="floating-background">
        <img ref={ball1Ref} src={ball1} alt="ball-1" className="floating-ball ball-1" />
        <img ref={ball2Ref} src={ball2} alt="ball-2" className="floating-ball ball-2" />
        <img ref={ball3Ref} src={ball3} alt="ball-3" className="floating-ball ball-3" />
        <img ref={ball4Ref} src={ball4} alt="ball-4" className="floating-ball ball-4" />
      </div>

      <div className="header">
        <div>
          <img className="logo" alt="phmg logo" src={logo} />
        </div>
        <p className='ivory-font'></p>
      </div>
      <div className="page-container">
      <h5 className="event ivory-font"><em className='event-time ivory-font'>Big news</em> is taking shape</h5>

        <div className="button-grid">
          <Link to="/bst" className="button-container">
            <button className="location-button">UK – 10am BST</button>
          </Link>
          <Link to="/cdt" className="button-container">
            <button className="location-button">Chicago & Man NA – 10am CDT</button>
          </Link>
          <Link to="/mst" className="button-container">
            <button className="location-button">Phoenix – 10am MST</button>
          </Link>
          <Link to="/aest" className="button-container">
            <button className="location-button">Brisbane & Man Aus – 10am AEST</button>
          </Link>
        </div>
      </div>
      <p className="footer ivory-font">Make <em className='ivory-font'>Your</em> Mark</p>
    </div>
  );
};

export default RegionSelection;